import React from "react";
import styled from "styled-components";
import { LargeBoldText, MidLargeSpacedText } from "../Typography";
import closeIcon from "../../assets/icons/close-icon.svg";
import gmailLogo from "../../assets/images/gmail-logo.jpg";
import office365Logo from "../../assets/images/office-365.svg";
import outlookLogo from "../../assets/images/outlook-logo.png";
import iCloudLogo from "../../assets/images/icloud-logo.png";
import csvLogo from "../../assets/images/csv-logo.jpeg";
import {
  ProfileModalPageWrapperDisplayModal,
  DarkBlurredBackground,
  CloseIconImage,
  ProfileModalContainerAddContacts,
  AddContactsContent,
  HeaderAndExplainerTextContainer,
  AddMultipleContactsModal,
} from "./index";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const AddMultipleContactsOptionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const AddMultipleContactsOption = styled.div`
  display: flex;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #e3e6e9;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0913462);
  cursor: pointer;
  p {
    margin: initial;
    text-align: center;
  }
  @media (max-width: 480px) {
    padding: 24px 5px 23px 5px;
  }
`;

const AddMultipleContactsOptionOtherOptions = styled(AddMultipleContactsOption)`
  padding: 15px 0px 15px 0px;
`;

const AddMultipleContactsOptionContainerMarginTop = styled(
  AddMultipleContactsOptionContainer
)`
  margin-top: 20px;
`;

const AddIconsContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  gap: 10px;
`;

const AddIconsContainerOtherOptions = styled(AddIconsContainer)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  gap: 5px;
  svg {
    width: 2em;
    height: 1.5em;
  }
`;

export function AddContactsModal({
  displayModal,
  setDisplayModal,
  toggleAddOrEditSingleContactModal,
}) {
  return (
    <ProfileModalPageWrapperDisplayModal displayModal={displayModal}>
      <DarkBlurredBackground onClick={setDisplayModal} />
      <ProfileModalContainerAddContacts>
        <CloseIconImage src={closeIcon} onClick={setDisplayModal} />
        <Grid item xs={12} sx={{ p: 3 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12}>
              <HeaderAndExplainerTextContainer>
                <LargeBoldText>Add Contacts</LargeBoldText>
                <MidLargeSpacedText>
                  Add or upload contacts to Revmo for an optimal search
                  experience.
                </MidLargeSpacedText>
              </HeaderAndExplainerTextContainer>
            </Grid>
            <Grid item xs={12}>
              <AddContactFromCloudsponge
                source="gmail"
                icon={gmailLogo}
                setDisplayModal={setDisplayModal}
              />
            </Grid>
            <Grid item xs={12}>
              <AddContactFromCloudsponge
                source="office365"
                icon={office365Logo}
                setDisplayModal={setDisplayModal}
              />
            </Grid>
            <Grid item xs={12}>
              <AddContactFromCloudsponge
                source="outlookcom"
                icon={outlookLogo}
                setDisplayModal={setDisplayModal}
              />
            </Grid>
            <Grid item xs={12}>
              <AddContactFromCloudsponge
                source="icloud"
                icon={iCloudLogo}
                setDisplayModal={setDisplayModal}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    More Options
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0.1}>
                    <Grid item xs={12} sx={{ p: 3 }}>
                      <AddContactFromCloudsponge
                        source="csv"
                        icon={csvLogo}
                        text="Upload from CSV"
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ p: 3 }}>
                      <AddContactsFromLinkedIn
                        source="csv"
                        icon={csvLogo}
                        text="Upload LinkedIn Connections"
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ p: 3 }}>
                      <AddIndividualContact
                        toggleAddOrEditSingleContactModal={
                          toggleAddOrEditSingleContactModal
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
        <AddContactsContent>
          <AddMultipleContactsOptionContainer></AddMultipleContactsOptionContainer>
          <AddMultipleContactsOptionContainerMarginTop></AddMultipleContactsOptionContainerMarginTop>
        </AddContactsContent>
      </ProfileModalContainerAddContacts>
    </ProfileModalPageWrapperDisplayModal>
  );
}

function AddIndividualContact({ toggleAddOrEditSingleContactModal }) {
  return (
    <AddMultipleContactsOptionOtherOptions
      onClick={() => toggleAddOrEditSingleContactModal()}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        sx={{ p: 1 }}
      >
        <Grid item xs={9}>
          <AddIconsContainerOtherOptions>
            <PersonAddIcon fontSize="large" />
            <Typography>Add individual contact</Typography>
          </AddIconsContainerOtherOptions>
        </Grid>
        <Grid item xs={3}>
          <Button>Upload</Button>
        </Grid>
      </Grid>
    </AddMultipleContactsOptionOtherOptions>
  );
}

const CloudspongeIcon = styled.img`
  max-height: 60px;
`;

function AddContactFromCloudsponge({ source, icon, text, setDisplayModal }) {
  return (
    <AddMultipleContactsOption
      className="cloudsponge-launch"
      data-cloudsponge-source={source}
      onClick={setDisplayModal}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        sx={{ p: 1 }}
      >
        <Grid item xs={9}>
          <AddIconsContainer>
            <CloudspongeIcon src={icon} />
            {!!text && <Typography>{text}</Typography>}
          </AddIconsContainer>
        </Grid>
        <Grid item xs={3}>
          <Button>Upload</Button>
        </Grid>
      </Grid>
    </AddMultipleContactsOption>
  );
}

function AddContactsFromLinkedIn({ source, icon, text, setDisplayModal }) {
  return (
    <AddMultipleContactsOption className="linkedin" onClick={setDisplayModal}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        sx={{ p: 1 }}
      >
        <Grid item xs={0}>
          {!!text && <Typography>{text}</Typography>}
        </Grid>
        <Grid item xs={3}>
          <Button>Upload</Button>
        </Grid>
      </Grid>
    </AddMultipleContactsOption>
  );
}
