import React from "react";
import { Chip, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function IntermediaryContext({
  personYouWouldLikeToViewIntroductionPathwayToContext,
}) {
  const theme = useTheme();

  const contextColour = (context) => {
    if (context == "ALUM") {
      return theme.palette.secondary.main;
    }
    if (context == "COLLEAGUE") {
      return theme.palette.secondary.dark;
    }
    if (context == "FMR_COLLEAGUE") {
      return theme.palette.success.light;
    }
    if (context == "COMPETITOR") {
      return theme.palette.grey.main;
    }
    if (context == "CONTACT") {
      return theme.palette.success.main;
    }
    if (context == "LINKEDIN") {
      return theme.palette.success.dark;
    }
    if (context == "SOCIAL") {
      return theme.palette.info.light;
    }
    if (context == "GEO") {
      return theme.palette.info.main;
    }
    if (context == "TXN") {
      return theme.palette.info.dark;
    }
  };
  if (
    personYouWouldLikeToViewIntroductionPathwayToContext.intermediary_contexts
  ) {
    return (
      <Stack direction="column" spacing={1} marginLeft="11px">
        {personYouWouldLikeToViewIntroductionPathwayToContext.intermediary_contexts
          .slice(0, 3)
          .map((context, index) => {
            return (
              <Chip
                key={index}
                sx={{
                  height: "20px",
                  width: "85px",
                  alignItems: "center",
                  borderRadius: "30px",
                  transition: "all .2s ease-in-out",
                  backgroundColor: contextColour(context),
                  fontSize: 9,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                label={context}
                color="secondary"
                size="small"
              ></Chip>
            );
          })}
      </Stack>
    );
  }
}
