import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

// project imports
import UserContactsList from "./UserContactsList";
import UserContactsCards from "./UserContactsCards";
import { ContactModals } from "containers/Contacts/components";
import { MainCard } from "components/DashboardNew";
import { borderRadius } from "containers/DashboardNew/theme";
import { AnimateButton } from "components/DashboardNew";
import {
  useFileUpload,
  useCustomFormErrors,
  useInternationalDiallingCodes,
} from "hooks";
import { createSingleContact } from "utils";
import { editSingleContact } from "containers/Contacts/utils";
import { useModalReducer } from "containers/Contacts/hooks";
import {
  ContactEmptyState,
  ContactErrorState,
} from "components/DashboardNew/List";
import { SearchResultsLoading } from "containers/Search/components";
import { useContact } from "hooks";

// assets
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import SearchIcon from "@mui/icons-material/Search";

function UserContacts() {
  // Local state variables
  const theme = useTheme();
  const location = useLocation();
  const [onContactsPage, setOnContactsPage] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [issuesWithReferralContacts, setIssuesWithReferralContacts] = useState({
    issue: "",
  });

  // Hooks
  const customFormErrorsHook = useCustomFormErrors();
  const diallingCodesHook = useInternationalDiallingCodes();
  const { diallingCode } = diallingCodesHook;

  const {
    modalsState,
    setUploadStatus,
    toggleAddContactsModal,
    toggleAddMultipleContactsModal,
    toggleAddOrEditSingleContactModal,
  } = useModalReducer();

  const {
    addContactToNetwork,
    getContacts,
    setItemsPerPage,
    setCurrentPage,
    setSearchName,
    deleteContactFromNetwork,
    contactsContextState,
    closeCloudspongeModal,
    submitAndAddContactsFromCloudsponge,
  } = useContact();

  const {
    failureMessage,
    fileUploadRef,
    loading,
    handleContactUploadsFromFile,
    handleChangeCSVDropdown,
    submitUploadsFromFile,
  } = useFileUpload(getContacts, setCurrentPage);

  const {
    mode,
    contactsAll,
    cloudspongeData,
    currentPage,
    numberOfNodes,
    itemsPerPage,
    searchName,
  } = contactsContextState;

  const resetValues = {
    searchName: "",
  };
  const { control, reset, handleSubmit } = useForm({
    defaultValues: resetValues,
  });

  const maxPage = Math.ceil(numberOfNodes / itemsPerPage);

  const editExistingContact = async (action, data, contactAddress) => {
    toggleAddOrEditSingleContactModal(action);
    await editSingleContact(data, contactAddress, contactDetails, diallingCode);
    await getContacts();
  };

  const showAddOrEditSingleContactModal = (type, index, contact) => {
    if (type === "edit") {
      toggleAddOrEditSingleContactModal("edit");
      setContactDetails(contact);
    } else {
      toggleAddOrEditSingleContactModal("add");
    }
  };

  const addNewContact = async (data, contactAddress) => {
    toggleAddOrEditSingleContactModal("add");
    setUploadStatus({ status: "Success" });
    window.plausible("Contact Upload", { props: { method: "Add Individual" } });
    await createSingleContact(data, contactAddress, diallingCode);
  };

  const handleSubmitManuallyAddOrEditNewContact = async (
    data,
    contactAddress,
    action
  ) => {
    try {
      if (action === "edit") {
        editExistingContact(action, data, contactAddress);
      } else if (action !== "edit") {
        addNewContact(data, contactAddress);
        addContactToNetwork(data, contactAddress);
      }
      diallingCodesHook.clearInternationalDiallingCode();
    } catch (err) {
      console.log(err);
      setUploadStatus("failedManualUpload");
    }
  };

  const deleteContact = async (uuid, index) => {
    await deleteContactFromNetwork(uuid, index);
  };

  const handleOnSubmit = async (event) => {
    setSearchName(event.searchName);
  };

  const handleDelete = () => {
    reset();
    setSearchName("");
  };

  const closeDisplayModal = () => {
    toggleAddOrEditSingleContactModal();
    diallingCodesHook.clearInternationalDiallingCode();
    setContactDetails({});
  };

  const closeSuccessModal = () => {
    setIssuesWithReferralContacts({
      issue: "",
    });
    setUploadStatus(null);
  };

  const options = ["10 Rows", "20 Rows", "30 Rows"];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    itemsPerPage ? itemsPerPage / 10 - 1 : 0
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setItemsPerPage((index + 1) * 10);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (location?.state?.addContacts) {
      toggleAddContactsModal(true);
    }
    if (location?.pathname == "/contacts") {
      setOnContactsPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cloudsponge = window.cloudsponge;
    cloudsponge.init({
      afterSubmitContacts: submitAndAddContactsFromCloudsponge,
    });
  });

  return (
    <>
      <ContactModals
        modalsState={modalsState}
        toggleAddContactsModal={toggleAddContactsModal}
        toggleAddOrEditSingleContactModal={toggleAddOrEditSingleContactModal}
        toggleAddMultipleContactsModal={toggleAddMultipleContactsModal}
        closeDisplayModal={closeDisplayModal}
        handleSubmitManuallyAddOrEditNewContact={
          handleSubmitManuallyAddOrEditNewContact
        }
        contactDetails={contactDetails}
        setContactDetails={setContactDetails}
        customFormErrorsHook={customFormErrorsHook}
        issuesWithReferralContacts={issuesWithReferralContacts}
        handleContactUploadsFromFile={handleContactUploadsFromFile}
        fileUploadRef={fileUploadRef}
        submitUploadsFromFile={submitUploadsFromFile}
        closeSuccessModal={closeSuccessModal}
        setUploadStatus={setUploadStatus}
        displayedPageView={"Network Contacts"}
        diallingCodesHook={diallingCodesHook}
        closeCloudspongeModal={closeCloudspongeModal}
        cloudspongeData={cloudspongeData}
        handleChangeCSVDropdown={handleChangeCSVDropdown}
        loading={loading}
        failureMessage={failureMessage}
      />
      <MainCard
        title={
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
            component="form"
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <Grid item>
              <Grid container alignItems="center" spacing={3}>
                <Grid item>
                  <Typography variant="h4">Contacts</Typography>
                </Grid>
                <Grid item>
                  {!!searchName && (
                    <Chip
                      label={"Name: " + searchName}
                      onDelete={handleDelete}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={3} direction="row">
                <Grid item>
                  <Controller
                    control={control}
                    name="searchName"
                    defaultValue=""
                    render={({ ...props }) => (
                      <OutlinedInput
                        placeholder="Search Contacts"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleSubmit(handleOnSubmit)}>
                              <SearchIcon stroke={1.5} size="1rem" />
                            </IconButton>
                          </InputAdornment>
                        }
                        size="small"
                        {...props}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <AnimateButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={toggleAddContactsModal}
                    >
                      Add Contacts
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        content={false}
        sx={{
          borderRadius: { borderRadius },
          border: "none",
          mt: { xs: !onContactsPage && 3, lg: 0 },
        }}
      >
        <>
          {mode === "loaded" && (
            <>
              <UserContactsList
                mode={mode}
                contactsAll={contactsAll}
                toggleAddOrEditSingleContactModal={
                  showAddOrEditSingleContactModal
                }
                diallingCodesHook={diallingCodesHook}
                deleteContact={deleteContact}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              />
              <UserContactsCards
                contactsAll={contactsAll}
                toggleAddOrEditSingleContactModal={
                  showAddOrEditSingleContactModal
                }
                deleteContact={deleteContact}
                diallingCodesHook={diallingCodesHook}
              />
            </>
          )}
          {mode === "idle" ||
            (mode === "noResults" && (
              <ContactEmptyState
                toggleAddContactsModal={toggleAddContactsModal}
              />
            ))}
          {mode === "error" && <ContactErrorState />}
          {mode === "loading" && <SearchResultsLoading />}
        </>
        <Grid item xs={12} sx={{ p: 3 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Pagination
                count={maxPage}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid item>
              <Button
                size="large"
                sx={{ color: theme.palette.grey[900] }}
                color="secondary"
                endIcon={<ExpandMoreRoundedIcon />}
                onClick={handleClick}
              >
                {options[selectedIndex]}
              </Button>
              <Menu
                id="menu-user-list-style1"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

export default UserContacts;
