import { useReducer } from "react";

const TOGGLE_ADD_CONTACTS_MODAL = "TOGGLE_ADD_CONTACTS_MODAL";
const TOGGLE_ADD_MULTIPLE_CONTACTS_MODAL = "TOGGLE_ADD_MULTIPLE_CONTACTS_MODAL";
const TOGGLE_ADD_OR_EDIT_CONTACT_MODAL = "TOGGLE_ADD_OR_EDIT_CONTACT_MODAl";
const UPLOAD_STATUS = "UPLOAD_STATUS";
const TOGGLE_ADD_OR_VIEW_TAGS = "TOGGLE_ADD_OR_VIEW_TAGS";

const useModalReducer = () => {
  const initialModalState = {
    showAddContactsModal: false,
    showAddMultipleContactsModal: false,
    showAddOrEditContactModal: false,
    addOrEditContact: null,
    showGmailUploadModal: false,
    uploadStatus: null,
    addOrViewTags: {
      displayModal: false,
      contact: null,
      tags: [],
    },
  };

  const modalReducer = (state, action) => {
    switch (action.type) {
      case TOGGLE_ADD_CONTACTS_MODAL:
        return {
          ...state,
          showAddContactsModal: !state.showAddContactsModal,
        };
      case TOGGLE_ADD_MULTIPLE_CONTACTS_MODAL:
        return {
          ...state,
          showAddContactsModal: false,
          showAddMultipleContactsModal:
            action.payload === "csv" || action.payload === "vcf"
              ? action.payload
              : false,
        };
      case TOGGLE_ADD_OR_EDIT_CONTACT_MODAL:
        return {
          ...state,
          showAddContactsModal: false,
          showAddOrEditContactModal: !state.showAddOrEditContactModal,
          addOrEditContact: action.payload ? action.payload : null,
        };
      case UPLOAD_STATUS:
        return {
          ...state,
          uploadStatus: action.payload,
        };
      case TOGGLE_ADD_OR_VIEW_TAGS:
        return {
          ...state,
          addOrViewTags: {
            displayModal: !state.addOrViewTags.displayModal,
            contact: action?.payload,
          },
        };
    }
  };

  const [state, dispatch] = useReducer(modalReducer, initialModalState);

  const setUploadStatus = (payload) => {
    return dispatch({ type: UPLOAD_STATUS, payload });
  };

  const toggleAddContactsModal = () => {
    return dispatch({ type: TOGGLE_ADD_CONTACTS_MODAL });
  };

  const toggleAddMultipleContactsModal = (payload) => {
    return dispatch({ type: TOGGLE_ADD_MULTIPLE_CONTACTS_MODAL, payload });
  };

  const toggleAddOrEditSingleContactModal = (payload) => {
    return dispatch({ type: TOGGLE_ADD_OR_EDIT_CONTACT_MODAL, payload });
  };

  const toggleAddOrViewTags = (payload) => {
    return dispatch({ type: TOGGLE_ADD_OR_VIEW_TAGS, payload });
  };

  return {
    modalsState: state,
    setUploadStatus,
    toggleAddContactsModal,
    toggleAddMultipleContactsModal,
    toggleAddOrEditSingleContactModal,
    toggleAddOrViewTags,
  };
};

export default useModalReducer;
