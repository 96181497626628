import styled from "styled-components";
import { BlueLinkTextUnderlined, LargeBoldText } from "../Typography";
import { DarkBlurredBackground } from "../Layout";

export const ProfileModalPageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 99vh;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    padding-top: 10px;
    margin-top: 0;
  }
`;

export const ProfileModalPageWrapperDisplayModal = styled(
  ProfileModalPageWrapper
)`
  ${(props) => {
    if (props.displayModal) {
      return `
        display: flex;
      `;
    } else {
      return `
        display: none;
      `;
    }
  }}
`;

export const BlueLinkTextUnderlinedFileUpload = styled(BlueLinkTextUnderlined)`
  position: absolute;
  left: 73%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  @media (max-width: 480px) {
    left: 72%;
  }
`;

export const ProfileModalContainer = styled.div`
  height: max-content;
  background: #ffffff;
  z-index: 200;
  padding: 54px 15px 61px 15px;
  width: 100%;
  max-width: 747px;
  max-height: 90vh;
  position: relative;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  @media (max-width: 767px) {
    padding-top: 62px;
    padding-bottom: 42px;
    max-height: 100vh;
  }
`;

export const ProfileModalContainerAddPosition = styled(ProfileModalContainer)`
  padding: 80px 15px 82px 15px;
  height: 770px;
  overflow-y: scroll;
  @media (max-width: 767px) {
    padding-top: 55px;
    padding-bottom: 32px;
    height: 100vh;
    border-radius: 0px;
  }
`;

export const CloseIconImage = styled.img`
  position: absolute;
  top: 24px;
  right: 28px;
  height: 26px;
  width: 26px;
  cursor: pointer;
  @media (max-width: 767px) {
    top: 15px;
    right: 13px;
  }
`;

export const EditProfileContent = styled.div`
  max-width: 539px;
  width: 100%;
`;

export const UserProfileImageContainer = styled.div`
  position: relative;
  background: white;
  height: 221px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
  border: 1px solid #e3e6e9;
  @media (max-width: 480px) {
    height: 140px;
    margin-bottom: 15px;
  }
`;

export const LargeBoldTextEditProfile = styled(LargeBoldText)`
  margin-bottom: 31px;
  text-align: center;
  @media (max-width: 480px) {
    margin-bottom: 18px;
  }
`;

export const EditPositionModalInputWrapper = styled.div`
  margin-top: 2px;
  div {
    input {
      font-weight: bold;
    }
  }
`;

export const ReferAFriendModalInputWrapper = styled(
  EditPositionModalInputWrapper
)`
  margin-top: 33px;
`;

export const AddNewPositionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ProfileModalContainerAddContacts = styled(ProfileModalContainer)`
  padding: 50px 20px 71px 20px;
`;

export const AddContactsContent = styled.div`
  max-width: 539px;
  width: 100%;
`;

export const HeaderAndExplainerTextContainer = styled.div`
  margin-bottom: 29px;
  p:first-child {
    margin-bottom: 20px;
    text-align: start;
  }
`;

export const HeaderAndExplainerTextContainerTags = styled(
  HeaderAndExplainerTextContainer
)`
  p:nth-child(2) {
    margin-bottom: 18px;
  }
  p:nth-child(3) {
    margin-bottom: 18px;
  }
`;

export const HeaderAndExplainerTextContainerMultipleContacts = styled(
  HeaderAndExplainerTextContainer
)`
  p:last-child {
    margin-top: 14px;
  }
`;

export const HeaderAndExplainerTextContainerReferAFriend = styled(
  HeaderAndExplainerTextContainer
)`
  margin-bottom: 29px;
  p:first-child {
    margin-bottom: 0;
  }
`;

export const ProfileModalContainerAddOrEditContact = styled(
  ProfileModalContainer
)`
  padding-bottom: 54px;
`;

export const FormTypeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormTypeHeaderAddContact = styled(FormTypeHeader)`
  align-items: flex-end;
  @media (max-width: 870px) {
    flex-direction: column;
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }
`;

export const HeaderAndExplainerTextContainerSingleContact = styled(
  HeaderAndExplainerTextContainer
)`
  margin-bottom: 39px;
`;

export const AddOrEditContactPersonalDetailsWrapper = styled.div`
  margin-top: 22px;
  margin-bottom: 39px;
`;

export const AddOrEditContactPositionDetailsWrapper = styled.div`
  margin-top: 22px;
  margin-bottom: 29px;
`;

export const ProfileModalContainerAddMultipleContacts = styled(
  ProfileModalContainerAddContacts
)`
  padding-top: 40px;
  padding-bottom: 43px;
  button {
    margin-top: 29px;
  }
`;

export const ProfileModalContainerSuccess = styled(ProfileModalContainer)`
  padding-top: 46px;
  padding-bottom: 68px;
  @media (max-width: 480px) {
    padding-top: 59px;
    padding-bottom: 49px;
  }
`;

export const ProfileModalContainerReferAFriend = styled(ProfileModalContainer)`
  padding-top: 72px;
  padding-bottom: 68px;
  @media (max-width: 480px) {
    padding-top: 59px;
    padding-bottom: 49px;
  }
`;

export const ProfileModalPageWrapperEditPasswordModal = styled(
  ProfileModalPageWrapper
)`
  top: unset;
  bottom: -800px;
`;

export const SuccessModalHeader = styled.div`
  margin-bottom: 40px;
`;

export const SuccessIconAndTextWrapper = styled.div`
  display: flex;
  margin-bottom: 29px;
  align-items: center;
`;

export const GreenCircleTickIcon = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 100%;
  border: 3px solid #18bfa1;
  img {
    width: 21px;
    height: 15px;
  }
`;

export * from "./EditPositionModal";
export * from "./AddContactsModal";
export * from "./AddOrEditContactModal";
export * from "./AddNewPositionModal";
export * from "./AddMultipleContactsModal";
export * from "./EditPositionModal";
export * from "./SuccessModal";
export * from "./ErrorModal";
export * from "./EditPersonalInfoModal";
export * from "./EditPasswordModal";
export * from "./UpdateProfileImageModal";
export * from "./FeedbackModal";
export { default as AddOrViewTagsModal } from "./TaggingModals/AddOrViewTagsModal";
export { default as TagMultipleContactsModal } from "./TaggingModals/TagMultipleContactsModal";
export * from "./ConfirmEmailSettingsModal";
export { DarkBlurredBackground };
export * from "./UpdateRequestStatus";
export * from "./ConfirmUserEmailAddressModal";
export * from "./CloudspongeUploadModal";
export * from "./FileTooLargeModal";
export * from "./NewModal";
