import React, { useState, useRef } from "react";
import {
  OnboardingError,
  OnboardingPersonalData,
  OnboardingProfessionalData,
  OnboardingLocationData,
  AddUserPhoto,
  OnboardingProfile,
} from "./components";
import {
  useUser,
  useProfile,
  useCustomFormErrors,
  useInternationalDiallingCodes,
  useEmailConfirmationCode,
} from "hooks";
import {
  OnboardingBody,
  InternalContainer,
  InternalContainerWrapper,
  Sidebar,
  ExitOnboarding,
} from "components/Onboarding";
import { ConfirmUserEmailAddressModal } from "components/Modal";

import {
  createProfile,
  updateProfileState,
  updateUserData,
  formatUserObject,
} from "./utils";
import history from "../../history";
import {
  saveOnboardingFieldsPrivateData,
  saveOnboardingFieldsPersonalData,
  saveOnboardingUserAddress,
  loadState,
} from "state/sessionStorage";

function Onboarding() {
  const [currentOnboardingPage, setCurrentOnboardingPage] = useState(1);
  const [onboardingFields, setOnboardingFields] = useState({});
  const initialState = {
    address1: "",
    personalLatLng: "",
    location: "",
    professionalLatLng: "",
  };

  const [userAddress, setUserAddress] = useState(
    loadState("userAddress") || initialState
  );

  const { user, checkForUser, logout } = useUser();
  const { createUserProfile } = useProfile();
  const toggleStateRef = useRef(true);

  const diallingCodesHook = useInternationalDiallingCodes();
  const emailConfirmationCodeHook = useEmailConfirmationCode();
  const {
    confirmationCodeData,
    getUserEmailConfirmationCode,
    closeConfirmationCodeModal,
  } = emailConfirmationCodeHook;

  const incrementPage = () => {
    setCurrentOnboardingPage(currentOnboardingPage + 1);
  };

  const handleChangeOnboardingPersonalData = (data) => {
    const formattedOnboardingValues = updateUserData(
      onboardingFields,
      setOnboardingFields,
      data,
      diallingCodesHook.diallingCode
    );
    saveOnboardingUserAddress(userAddress);
    saveOnboardingFieldsPersonalData(formattedOnboardingValues);
    incrementPage();
  };

  const handleChangeOnboardingProfessionalData = (data) => {
    const formattedOnboardingValues = updateUserData(
      onboardingFields,
      setOnboardingFields,
      data,
      diallingCodesHook.diallingCode
    );
    saveOnboardingFieldsPrivateData(formattedOnboardingValues);
    user.attributes.email === data.companyEmail
      ? incrementPage()
      : getUserEmailConfirmationCode(data.companyEmail);
  };

  const handleChangeOnboardingLocationData = (data) => {
    const formattedOnboardingValues = updateUserData(
      onboardingFields,
      setOnboardingFields,
      data,
      diallingCodesHook.diallingCode
    );
    saveOnboardingFieldsPrivateData(formattedOnboardingValues);
    saveOnboardingUserAddress(userAddress);
    if (
      userHasSelectedALocation(
        userAddress.personalLatLng,
        customFormErrorsHook.setCustomFormErrors
      )
    ) {
      incrementPage();
    }
  };

  const handleChangeAddUserPhoto = (data) => {
    updateUserData(onboardingFields, setOnboardingFields, data);
    incrementPage();
  };

  const userHasSelectedALocation = (latLng, setError) => {
    if (!latLng?.lat) {
      setError({
        location: "Please select a location from the dropdown",
      });
    } else {
      return true;
    }
  };

  const handleSubmitOnboarding = async () => {
    const userOnboardingData = formatUserObject(
      onboardingFields,
      userAddress,
      user,
      toggleStateRef,
      diallingCodesHook.diallingCode
    );
    try {
      await createProfile(userOnboardingData, user);
    } catch (e) {
      // Show error page if onboarding fails
      setCurrentOnboardingPage(0);
      return;
    }
    updateProfileState(userOnboardingData, createUserProfile);
    await checkForUser();
    history.push("/dashboard");
  };

  const customFormErrorsHook = useCustomFormErrors();

  const prevPage = () => {
    setCurrentOnboardingPage(currentOnboardingPage - 1);
  };

  const checkConfirmationCodeIsCorrect = (data, setErrorMessage) => {
    if (data.confirmationCode === confirmationCodeData.verification_code) {
      closeConfirmationCodeModal();
      incrementPage();
      setErrorMessage("");
    } else {
      setErrorMessage("The confirmation code you have entered is incorrect");
    }
  };

  return (
    <>
      <OnboardingBody>
        <div onClick={logout}>
          <ExitOnboarding />
        </div>
        <Sidebar />
        <InternalContainerWrapper>
          <InternalContainer>
            {currentOnboardingPage === 0 && (
              <OnboardingError handleSubmit={incrementPage} />
            )}
            {currentOnboardingPage === 1 && (
              <OnboardingPersonalData
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                onboardingFields={onboardingFields}
                setOnboardingFields={setOnboardingFields}
                currentOnboardingPage={currentOnboardingPage}
                diallingCodesHook={diallingCodesHook}
                handleChangeOnboardingPersonalData={
                  handleChangeOnboardingPersonalData
                }
              />
            )}
            {currentOnboardingPage === 2 && (
              <OnboardingProfessionalData
                setOnboardingFields={setOnboardingFields}
                prevPage={prevPage}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                toggleStateRef={toggleStateRef}
                currentOnboardingPage={currentOnboardingPage}
                customFormErrorsHook={customFormErrorsHook}
                diallingCodesHook={diallingCodesHook}
                handleChangeOnboardingProfessionalData={
                  handleChangeOnboardingProfessionalData
                }
                checkConfirmationCodeIsCorrect={checkConfirmationCodeIsCorrect}
              />
            )}
            {currentOnboardingPage === 3 && (
              <OnboardingLocationData
                setOnboardingFields={setOnboardingFields}
                prevPage={prevPage}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                toggleStateRef={toggleStateRef}
                currentOnboardingPage={currentOnboardingPage}
                customFormErrorsHook={customFormErrorsHook}
                diallingCodesHook={diallingCodesHook}
                handleChangeOnboardingLocationData={
                  handleChangeOnboardingLocationData
                }
                checkConfirmationCodeIsCorrect={checkConfirmationCodeIsCorrect}
              />
            )}
            {currentOnboardingPage === 4 && (
              <AddUserPhoto
                prevPage={prevPage}
                handleChangeAddUserPhoto={handleChangeAddUserPhoto}
                profile={formatUserObject(
                  onboardingFields,
                  userAddress,
                  user,
                  toggleStateRef,
                  diallingCodesHook.diallingCode
                )}
              />
            )}
            {currentOnboardingPage === 5 && (
              <OnboardingProfile
                handleSubmitOnboarding={handleSubmitOnboarding}
                profile={formatUserObject(
                  onboardingFields,
                  userAddress,
                  user,
                  toggleStateRef,
                  diallingCodesHook.diallingCode
                )}
              />
            )}
            <ConfirmUserEmailAddressModal
              confirmUserEmail={checkConfirmationCodeIsCorrect}
              displayModal={confirmationCodeData.displayModal}
              setDisplayModal={closeConfirmationCodeModal}
            />
          </InternalContainer>
        </InternalContainerWrapper>
      </OnboardingBody>
    </>
  );
}

export default Onboarding;
